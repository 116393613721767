import { BaseRequestMetadata, Retailer } from '@supy/common';

import { ImpersonationRetailersFilters } from '../core';

export class ImpersonationRetailersGetMany {
  static readonly type = '[ImpersonationRetailers] GetMany';
}

export class ImpersonationRetailersInitFilters {
  static readonly type = '[ImpersonationRetailers] InitFilters';
}

export class ImpersonationRetailersPatchFilters {
  static readonly type = '[ImpersonationRetailers] PatchFilters';
  constructor(readonly payload: Partial<ImpersonationRetailersFilters>) {}
}

export class ImpersonationRetailersSelectRetailer {
  static readonly type = '[ImpersonationRetailers] SelectRetailer';
  constructor(readonly payload?: Retailer) {}
}

export class ImpersonationRetailersResetFilters {
  static readonly type = '[ImpersonationRetailers] ResetFilters';
}

export class ImpersonationRetailersPatchRequestMetadata {
  static readonly type = '[ImpersonationRetailers] PatchRequestMetadata';
  constructor(readonly payload: Partial<BaseRequestMetadata>) {}
}

export class ImpersonationRetailersGetCurrentUser {
  static readonly type = '[ImpersonationRetailers] GetCurrentUser';
}
