import { first, forkJoin, map, Observable, switchMap, take } from 'rxjs';
import { CellClickedEvent, ColDef } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, ViewChild } from '@angular/core';
import { GridPagingMode } from '@infragistics/igniteui-angular';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';

import {
  BranchType,
  Destroyable,
  OutletStateEnum,
  Query,
  QueryPaging,
  Retailer,
  SkeletonObjectType,
  Supplier,
  SupyCommonModule,
  UserWithBranches,
} from '@supy/common';
import {
  DialogService,
  FilterChange,
  FilterGroup,
  FiltersModule,
  GridPocComponent,
  GridPocModule,
  LoadingOverlayModule,
} from '@supy/components';
import { CountriesState } from '@supy/countries';
import { PosItemResetFilters } from '@supy/integrations';
import {
  InventoryRecipeResetFilter,
  InventoryStockCountItemsResetFilters,
  InventoryStocksReset,
  InventoryStocksResetFilter,
} from '@supy/inventory';
import { RetailerItemCategoryGetMany } from '@supy/retailer-item-categories';
import { CurrentRetailerSet, OutletsProps, OutletsService, RetailersService } from '@supy/retailers';
import { GetSettings, RetailerSettingsGet } from '@supy/settings';
import { CurrentUserState, GetCurrentUserSuccess } from '@supy/users';

import { ImpersonationRetailersFilters } from '../../core';
import {
  ImpersonationRetailersGetMany,
  ImpersonationRetailersInitFilters,
  ImpersonationRetailersPatchFilters,
  ImpersonationRetailersPatchRequestMetadata,
  ImpersonationRetailersResetFilters,
  ImpersonationRetailersSelectRetailer,
  ImpersonationRetailersState,
} from '../../store';
import { getFilterConfig } from './filter-config';

@Component({
  standalone: true,
  selector: 'supy-retailers-selection',
  templateUrl: './retailers-selection.component.html',
  styleUrls: ['./retailers-selection.component.scss'],
  providers: [DialogService],
  imports: [FiltersModule, GridPocModule, LoadingOverlayModule, CommonModule, SupyCommonModule],
})
export class RetailersSelectionComponent extends Destroyable implements OnInit {
  @ViewChild(GridPocComponent) protected readonly gridPoc: GridPocComponent<Retailer>;

  @Select(actionsExecuting([ImpersonationRetailersGetMany]))
  protected readonly isLoading$: Observable<ActionsExecuting>;

  protected readonly isPreparingRetailer = signal(false);

  protected readonly retailers = this.store.selectSignal(ImpersonationRetailersState.retailers);
  protected readonly requestMetadata = this.store.selectSignal(ImpersonationRetailersState.requestMetadata);
  protected readonly responseMetadata = this.store.selectSignal(ImpersonationRetailersState.responseMetadata);

  protected readonly paginationMode = GridPagingMode.Remote;

  protected columnDefs: ColDef<Retailer>[];

  protected readonly filtersGroup = new FilterGroup<FilterChange<{ readonly country: string }>>(getFilterConfig());

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly outletsService: OutletsService,
    private readonly retailersService: RetailersService,
  ) {
    super();
  }

  ngOnInit(): void {
    const currentUser = this.store.selectSnapshot(CurrentUserState.getCurrentUser) as UserWithBranches;

    this.store.dispatch([
      new ImpersonationRetailersSelectRetailer(),
      new CurrentRetailerSet({ id: null }),
      new GetCurrentUserSuccess({
        ...currentUser,
        retailers: [],
        retailerIds: [],
        branchIds: [],
        outletIds: [],
        centralKitchenIds: [],
      }),
      new ImpersonationRetailersPatchRequestMetadata({ page: 0 }),
      new ImpersonationRetailersResetFilters(),
    ]);
    this.setColumnDefs();
    this.setupFilters();
    this.fetchRetailers();
  }

  protected onPageChange(page: number): void {
    this.store.dispatch(new ImpersonationRetailersPatchRequestMetadata({ page }));
    this.fetchRetailers();
  }

  protected onApplyFilters(filter: Partial<ImpersonationRetailersFilters>): void {
    const { search: name, ...rest } = filter;

    this.store.dispatch(
      new ImpersonationRetailersPatchFilters({
        ...rest,
        name,
      }),
    );
  }

  protected onClearFilters(): void {
    this.store.dispatch(new ImpersonationRetailersResetFilters());
  }

  protected onCellClick(event: CellClickedEvent<Retailer>): void {
    this.isPreparingRetailer.set(true);

    const { data } = event;

    const retailer = data as Retailer;
    const retailerId = data?.id as string;
    const currentUser = this.store.selectSnapshot(CurrentUserState.getCurrentUser) as UserWithBranches;

    const outletsQuery = new Query<OutletsProps>({
      paging: QueryPaging.NoLimit,
      filtering: [
        { by: 'retailer.id', op: 'eq', match: retailerId },
        { by: 'state', op: 'eq', match: OutletStateEnum.Active },
      ],
    });

    const suppliersQuery = new Query<Supplier>({
      paging: QueryPaging.NoLimit,
    });

    this.actions$
      .pipe(ofActionCompleted(ImpersonationRetailersSelectRetailer))
      .pipe(
        take(1),
        switchMap(() =>
          forkJoin([
            this.outletsService.getOutletsBff(outletsQuery).pipe(map(res => res.data)),
            this.retailersService.getRetailersRelatedSuppliers(retailerId, suppliersQuery).pipe(map(res => res.data)),
          ]),
        ),
      )
      .subscribe(([outlets, suppliers]) => {
        const branches = outlets.flatMap(outlet =>
          outlet.branches.map(branch => ({
            ...branch,
            retailer,
            retailerId: retailer.id,
          })),
        );

        const centralKitchens = branches.filter(branch => branch.type === BranchType.centralKitchen);

        this.store.dispatch([
          new Navigate(['orders']),
          new GetCurrentUserSuccess({
            ...currentUser,
            retailers: [{ ...retailer, outlets, centralKitchens }],
            retailerIds: [retailerId],
            outletIds: outlets.map(({ id }) => id),
            branchIds: branches.map(({ id }) => id),
            centralKitchenIds: centralKitchens.map(({ id }) => id),
          }),
        ]);

        this.isPreparingRetailer.set(false);
      });

    this.store.dispatch([
      new ImpersonationRetailersSelectRetailer(data),
      new GetCurrentUserSuccess({
        ...currentUser,
        retailers: [{ ...retailer, outlets: [], centralKitchens: [] }],
        centralKitchenIds: [],
      }),
      new CurrentRetailerSet({ id: retailerId, stopRedirect: true }),
      new GetSettings({ retailerId }),
      new RetailerSettingsGet({ retailerId }),
      new InventoryRecipeResetFilter(),
      new InventoryStockCountItemsResetFilters(),
      new InventoryStocksResetFilter(),
      new PosItemResetFilters(),
      new RetailerItemCategoryGetMany(),
      new InventoryStocksReset(),
    ]);
  }

  private setColumnDefs(): void {
    const columnDefs: ColDef<Retailer>[] = [
      {
        headerName: 'Retailer Id',
        field: 'id',
        flex: 1,
      },
      {
        headerName: 'Retailer Name',
        field: 'name',
        flex: 2,
      },
      {
        headerName: 'Country',
        field: 'country',
        flex: 1,
        valueGetter: ({ data }) =>
          this.store.selectSnapshot(CountriesState.getCountry)(data?.country?.id as string)?.name ??
          (data?.country as SkeletonObjectType)?.name,
      },
      {
        headerName: 'No. of Locations',
        flex: 0.6,
        valueGetter: ({ data }) => (data?.branches as unknown as string[])?.length,
      },
    ];

    this.columnDefs = columnDefs;
  }

  private setupFilters(): void {
    this.store.dispatch(new ImpersonationRetailersInitFilters());

    this.store
      .select(CountriesState.countries)
      .pipe(first(countries => countries?.length > 0))
      .subscribe(countries => {
        this.filtersGroup.updateSelectionProperty('country', {
          options: countries,
        });
      });
  }

  private fetchRetailers(): void {
    this.store.dispatch(new ImpersonationRetailersGetMany());
  }
}
