import { FilterConfig, SelectType } from '@supy/components';

export function getFilterConfig(): FilterConfig {
  return {
    search: {
      placeholder: 'Search Retailers',
    },
    dropdowns: [
      {
        name: 'country',
        options: [],
        placeholder: 'Countries',
        selectType: SelectType.Select,
        valueKey: 'id',
        displayKey: 'name',
        width: '12rem',
      },
    ],
  };
}
